* {
	box-sizing: border-box;
}

/* this is what I use to place the menu thing wherever I want*/
.container-socials {
	position: absolute;
	/*top: 4em;*/
    bottom: 0em;
	right: 0.5em;
    z-index: 200;

    --circle-size: 2.5em;
    --top: 2em;
    --bottom: 2em;
    --void: 0.25em;
    --icon-size: 1.25em;
    --top-toggle: -3em;
    --bottom-toggle: 0.5em;
    --right-toggle: 0.5em;
}

.container-socials.open {
    --right-toggle: var(--width-panel-right);
}

.vh100 {
    height: calc(var(--vh) * 100);
    /*height: -moz-available;*/          /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    /*height: stretch;*/
}

/* this toggles the whole damn thing */
.menu-toggle {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: var(--color-secondary);
	box-shadow: 4px 4px 2px 1px #00000033;
	
	position: absolute;
	/*z-index: 50;*/
	/*top: var(--top-toggle);*/
    bottom: var(--bottom-toggle);
	right: var(--right-toggle);
	display: flex;
    justify-content: center;
    align-items: center;
}

.menu-toggle:hover {
    cursor: pointer;
    background-color: var(--color-secondary-soft);
}


.menu-toggle svg{
    color:#fff;
    font-size: 2em;
    transition: 0.4s;
}

/* Single Template buttons */
.btn-app {
    width: var(--circle-size);
	height: var(--circle-size);
	border-radius: 50%;
	position: absolute;
    display: block;
	
	background-color: var(--color-secondary);
	color:#fff;
	text-align: center;
}
.btn-app:hover {
    background-color: var(--color-secondary-soft);
}
.btn-app svg {
    line-height: 2.5em;
}
.btn-app a {
    color: #fff;
}

/* add shadow only when templates are activated */
.open .btn-app {
	box-shadow: 4px 4px 2px 1px #00000033;
}

/* The most-popular apps in a circular thing */
.menu-round {
	position: absolute;
	top: 0;
	right: 0;
	z-index: -1;
}

.menu-round .btn-app {
    /*top: calc(var(--top-toggle) * 0.75);*/
    bottom: var(--bottom-toggle * 0.75);
    right: var(--right-toggle);
    transition: 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: var(--icon-size);
}

.menu-round.open a:nth-of-type(1) .btn-app {
    right: calc(var(--right-toggle) + 0.5em);
    bottom: calc(var(--bottom-toggle) + 3.5em);
    transition-delay: 0.2s;
} 

.menu-round.open a:nth-of-type(2) .btn-app {
    right: calc(var(--right-toggle) + 3.5em);
    bottom: calc(var(--bottom-toggle) - 0.5em);
    transition-delay: 0.1s;
}
.menu-round.open a:nth-of-type(3) .btn-app {
    right: calc(var(--right-toggle) + 3.5em);
    bottom: calc(var(--bottom-toggle) + 2.5em);
}

/* stacked template icons */
.menu-line {
	position: absolute;
    /*top: var(--top-toggle);*/
    bottom: var(--bottom-toggle);
	/*z-index: 20;*/
}

.menu-line .btn-app {
    top: 0.5em;
    right: -4.75em;
    transition: 0.35s;
    transition-delay: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--icon-size);
}

.menu-line.open {
    left: 0.5em;
    /*top: calc(0.75em + var(--top-toggle));*/
    bottom: calc(0.75em + var(--bottom-toggle));
    /*z-index: 120;*/
}

.menu-line.open .btn-app {
    right: calc(-1em + var(--right-toggle));
    display: block;
}

@media (max-width: 1250px) {
    .menu-line.open {
        left: 0.5em;
    }
    .menu-line .btn-app {
        right: -3.25em;
        pointer-events: none;
        display: none;
    }
    .menu-line.open .btn-app {
        right: calc(1em + var(--right-toggle));
    }
}
@media (max-width: 900px) {
    .menu-line.open {
        left: 0;
    }
    .menu-line .btn-app {
        right: -3em;
        pointer-events: none;
        display: none;
    }
    .menu-line.open .btn-app {
        right: calc(1em + var(--right-toggle));
    }
}
@media (max-width: 767px) {
    .menu-line .btn-app {
        right: -2em;
        pointer-events: none;
        display: none;
    }
    .menu-line.open .btn-app {
        right: calc(1.25em + var(--right-toggle));
    }
}
@media (max-width: 600px) {
    .menu-line.open {
        left: -0.75em;
    }
    .menu-round {
        top: -4em;
    }
    .menu-round.open {
        top: 0;
    }
}

.menu-line.open a:nth-of-type(1) .btn-app {
    /*top: calc(var(--top) + 1 * (var(--circle-size) + var(--void)));*/
    bottom: calc(var(--bottom) + 1 * (var(--circle-size) + var(--void)));
}
.menu-line.open a:nth-of-type(2) .btn-app {
    /*top: calc(var(--top) + 2 * (var(--circle-size) + var(--void)));*/
    bottom: calc(var(--bottom) + 2 * (var(--circle-size) + var(--void)));
}
.menu-line.open a:nth-of-type(3) .btn-app {
    /*top: calc(var(--top) + 3 * (var(--circle-size) + var(--void)));*/
    bottom: calc(var(--bottom) + 3 * (var(--circle-size) + var(--void)));
}
.menu-line.open a:nth-of-type(4) .btn-app {
    /*top: calc(var(--top) + 4 * (var(--circle-size) + var(--void)));*/
    bottom: calc(var(--bottom) + 4 * (var(--circle-size) + var(--void)));
}