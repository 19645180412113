body {
  /* Fix mobile issue hiding the footer */
  min-height: 100vh;
  /*min-height: -moz-available;*/          /* WebKit-based browsers will ignore this. */
  min-height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  /*min-height: stretch;*/

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
}

html {
  height: 100vh;
  /*height: -moz-available;*/          /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  /*height: stretch;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* References on articles*/
ol li a {
  color: var(--color-text);
  font-weight: 600;
}
ol li a:hover {
  color: var(--color-primary-soft)
}
ol li a:visited {
  color: var(--color-primary)
}