html,body {
  --dark-cyan: #2D9894ff;
  --tangelo: #FD4B09ff;
  --tangelo-soft: #EC3A05DD;
  --jonquil: #F9CF20ff;
  --jonquil-soft: #E8BE10FF;
  --antiflash-white: #F0F4F8ff;
  --antiflash-white-soft: #F0F4F8FF;
  --night: #13130Fff;
  --night-soft: #23231FFF;

  --color-background: var(--night);
  --color-background-soft: var(--night-soft);
  --color-text: var(--antiflash-white);
  --color-text-soft: var(--antiflash-white-soft);
  --color-primary: var(--jonquil);
  --color-primary-soft: var(--jonquil-soft);
  --color-secondary: var(--tangelo);
  --color-secondary-soft: var(--tangelo-soft);
}

html,body {
  overflow-y: hidden;
  background-color: #0C0E10;
  background-image: url('assets/img/radio-tango.webp');
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
  background-position: center;

  /*Word breaking*/
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  /*Support for old browsers*/
  word-wrap: break-word;

  color: var(--color-text);  
}

html.bg-hide, body.bg-hide {
  background-color: #000C;
  background-blend-mode: overlay;
}

h2,h3,h4,h5,h6 {
  font-weight: 600;
}

blockquote {
  margin: 0 0 2em 0;
  font-style: italic;
  font-weight: 400;
  border-left: 0.25em solid var(--color-text);
  padding: 0 0 0 2em;
  font-size: 0.9em;
}

button.act, button.act:focus {
  background-color: var(--color-primary-soft);
  color: var(--color-text)
}
button.list-group-item-action:hover {
  background-color: var(--color-primary);
}

/* Avoid animations on panels when loading*/
.preload-prevent-animation *{
  animation-duration: 0s !important;
  -webkit-animation-duration: 0s !important;
  transition: background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important;
} 

/* Boostrap style patch */
.card input, .card input:focus{
  color: #222222CC;
}
button.btn {
  --bs-btn-color: var(--color-text);
  --bs-btn-hover-color: var(--color-text);
  --bs-btn-active-color: var(--color-text);

  --bs-btn-border-color: var(--color-background);
  --bs-btn-hover-border-color: var(--color-background);
  --bs-btn-active-border-color: var(--color-background);
  
  border: none;
}
button.btn-primary {
  --bs-btn-bg: var(--color-primary);
  --bs-btn-hover-bg: var(--color-primary-soft);
  --bs-btn-active-bg: var(--color-primary-soft);
  --bs-btn-disabled-bg: var(--color-primary-soft);
}
button.btn-secondary {
  --bs-btn-bg: var(--color-secondary);
  --bs-btn-hover-bg: var(--color-secondary-soft);
  --bs-btn-active-bg: var(--color-secondary-soft);
  --bs-btn-disabled-bg: var(--color-secondary-soft);
}

.card {
  --bs-card-border-color: var(--color-background);
  --bs-card-cap-bg: var(--color-background-soft);
  --bs-card-bg: var(--color-background-soft);
  --bs-body-color: var(--color-text);
}

.welcome-title {
  color: var(--color-text);
}

.container-socials-inline {
  margin-bottom: 1.5em;
  display: flex;
  justify-content: space-around;
}
.container-socials-inline a{
  color: var(--color-secondary-soft);
  font-size: 1.5em;
}