:root {
    --opacity-land: 0.95;
}

#land {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    height: 100vh;
    background-color: black;
    padding-left: calc(3em + var(--width-button));
    padding-right: 3em;
    overflow-y: auto;
}

#land.open {
    opacity: var(--opacity-land);
    width: calc(100vw);
    z-index: 120;
}
#land.close,#land.close * {
    animation: slideLeft3 750ms ease forwards;
    width: 0;
    opacity: 0;
}

#land .but-close {
    position: absolute;
    right: 2em;
    top: 0;
    font-size: 2.5em;
    background-color: transparent;
    cursor: pointer;
}

#land h1, #land h2, #land h3 {
    color: var(--color-primary);
}

#land h4, #land h5, #land h6{
    color: var(--color-secondary);
}

@media (max-width: 800px) {
    #land.open #land-container {
        width: calc(100vw - (0.5em + 2.5em + 0.2))
    }
    #land .but-close {
        right: 0.5em;
    }

}

@keyframes slideLeft3 {
    from {
        opacity: var(--opacity-land);
        width: calc(100vw - var(--width-button))
    }

    to {
        opacity: 0;
        width: 0;
    }
}